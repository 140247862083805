import React, { useEffect, useRef, useState } from "react"
import Prismic from "@prismicio/client"
import { PrismicText } from "@prismicio/react"
import { asText, asLink } from "@prismicio/helpers"
import { osVersion, isMobileSafari, isSafari } from "react-device-detect"
import classNames from "classnames"
import { useDispatch } from "react-redux"

import MediaBlock from "../../MediaBlock/MediaBlock"
import CTA from "../../CTA/CTA"
import TextWithCTA from "../../common/TextWithCTA/TextWithCTA"
import ModalNav from "../../common/ModalNav"
import ImageHighlight from "../../common/ImageHighlight/ImageHighlight"
import GoodModal from "../../Goods/Modal/Modal"

import testimonialBg from "../../../images/shared/header-bg.webp"
import testimonialBgPNG from "../../../images/shared/header-bg.png"
import medialinkBlackLogo from "../../../images/UTA_MediaLink_logo_BLACK.png"

import "./ExpertiseModal.scss"
import { setViewAllData } from "../../../state/reducers/utils"
import TestimonialVideo from "./TestimonialVideo"

const client = Prismic.client(process.env.GATSBY_PRISMIC_API_ENDPOINT, {
  accessToken: process.env.GATSBY_PRISMIC_API_KEY,
})

const ExpertiseModal = ({
  data,
  serviceIndex,
  closeModal,
  showContactForm,
  setServiceOpened,
  noNav,
  inEvent,
  servicesData,
}) => {
  const defaultTestimonialData = {
    text: null,
    video: null,
  }
  const dispatch = useDispatch()
  const expertiseModalEl = useRef(null)

  const [currentIndex, setCurrentIndex] = useState(serviceIndex)
  const [service, setService] = useState(data[serviceIndex])
  const [changing, setChanging] = useState(true)
  const [goods, setGoods] = useState()
  const [goodModalContent, setGoodModalContent] = useState(null)
  const [showGoodModal, setShowGoodModal] = useState(false)
  const [activeVideo, setActiveVideo] = useState()
  const [testimonialData, setTestimonialData] = useState(defaultTestimonialData)

  useEffect(() => {
    setCurrentIndex(serviceIndex)
    setTestimonialData(defaultTestimonialData)
  }, [serviceIndex])

  useEffect(() => {
    if (!servicesData) return
    setTestimonialData({
      text:
        servicesData[`${data[serviceIndex].prismic_key}_text_testimonial`] &&
        asText(
          servicesData[`${data[serviceIndex].prismic_key}_text_testimonial`]
        )
          ? {
              text: servicesData[
                `${data[serviceIndex].prismic_key}_text_testimonial`
              ],
              byline:
                servicesData[`${data[serviceIndex].prismic_key}_text_byline`],
            }
          : null,
      video:
        servicesData[`${data[serviceIndex].prismic_key}_testimonials`] &&
        servicesData[`${data[serviceIndex].prismic_key}_testimonials`][0]
          .video &&
        servicesData[`${data[serviceIndex].prismic_key}_testimonials`][0].video
          .embed_url
          ? servicesData[`${data[serviceIndex].prismic_key}_testimonials`]
          : null,
    })
  }, [serviceIndex, servicesData, data])

  useEffect(() => {
    setServiceOpened(true)
  }, [])

  const openGoodModal = (good) => {
    setChanging(true)
    setTimeout(() => {
      setGoodModalContent(good)
      setTimeout(() => setChanging(false), 500)
    }, 500)
  }

  const doQuery = () => {
    if (!data[currentIndex].goods_tag) {
      return
    }
    client
      .query(
        Prismic.Predicates.at("document.tags", [data[currentIndex].goods_tag]),
        { pageSize: 2 }
      )
      .then((goodsData) => {
        setGoods(
          goodsData.results.map((gr) => {
            const grData = { ...gr.data }
            grData.id = gr.id
            return grData
          })
        )
      }, [])
  }

  useEffect(() => {
    if (goodModalContent) {
      setTimeout(() => {
        setShowGoodModal(true)
      }, 250)
    }
  }, [goodModalContent])

  useEffect(() => {
    // if (!changing) {
    setChanging(true)
    if (!inEvent) {
      window.location.hash = `${data[currentIndex].slug}`
    }
    setTimeout(() => {
      if (expertiseModalEl && expertiseModalEl.current)
        expertiseModalEl.current.scrollTo(0, 0)
      if (showGoodModal) {
        setShowGoodModal(false)
        setGoodModalContent(null)
      }
      doQuery()
      setService(data[currentIndex])
    }, 375)
    setTimeout(() => {
      setChanging(false)
    }, 750)
  }, [currentIndex])

  useEffect(() => {
    if (!goods) {
      doQuery()
    }
  })

  const viewAll = (e) => {
    closeModal(e, true)
    dispatch(setViewAllData({ tagId: service.title, type: "tag" }))
  }

  const openTestimonialVideo = (videoId) => {
    setActiveVideo(videoId)
  }

  return (
    <>
      {!noNav && (
        <ModalNav
          items={data}
          labelIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          closeModal={closeModal}
          isExpertise
        />
      )}

      {goodModalContent && (
        <div
          className={classNames("person-good-modal", {
            "person-good-modal--visible": showGoodModal,
          })}
        >
          <GoodModal
            insideModal
            selectedArticle={{ data: goodModalContent }}
            articles={[{ data: goodModalContent }]}
          />
        </div>
      )}

      {!goodModalContent && (
        <div className="expertise-modal" ref={expertiseModalEl}>
          <div
            className={classNames("expertise-modal__inner", {
              "expertise-modal__inner--changing": changing,
            })}
          >
            <h2 className="expertise-modal__title">{service.title}</h2>
            <div className="expertise-modal__intro">
              <h3>{service.description}</h3>
              <p>{service.body}</p>
            </div>
            <ul
              className={classNames("expertise-modal__bullets", {
                "expertise-modal__bullets--no-goods": !goods,
              })}
            >
              {service.bullets.map((bullet, i) => (
                <React.Fragment key={bullet.id}>
                  {i % 2 === 0 ? <div className="line" /> : ""}
                  <li>
                    <p className="eyebrow">{bullet.title}</p>
                    <p className="body">{bullet.description}</p>
                  </li>
                </React.Fragment>
              ))}
            </ul>

            {testimonialData.text && (
              <div className="expertise-modal__testimonial">
                <ImageHighlight
                  bgImage={
                    isSafari ||
                    (isMobileSafari && osVersion.match(/13\.|12\./g))
                      ? testimonialBgPNG
                      : testimonialBg
                  }
                >
                  <div className="testimonial">
                    <div className="testimonial__text">
                      <PrismicText field={testimonialData.text.text} />
                    </div>
                    <p className="testimonial__byline">
                      <span className="emdash">—</span>{" "}
                      <PrismicText field={testimonialData.text.byline} />
                    </p>
                  </div>
                </ImageHighlight>
                {testimonialData.video && (
                  <div
                    className={`
                      expertise-modal__videos
                      expertise-modal__videos--${testimonialData.video.length}
                    `}
                  >
                    {testimonialData.video.map((video, index) => (
                      <TestimonialVideo
                        key={asText(video.first_name) + asText(video.last_name)}
                        video={{
                          ...video,
                          id: data[serviceIndex].prismic_key + index,
                        }}
                        openTestimonialVideo={() =>
                          openTestimonialVideo(
                            data[serviceIndex].prismic_key + index
                          )
                        }
                        activeVideo={activeVideo}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
            {goods && (
              <div className="expertise-modal__relevant-goods">
                <h3>Relevant Goods</h3>
                <button type="button" className="view-all" onClick={viewAll}>
                  View all
                </button>
                <div className="expertise-modal__relevant-goods__wrapper">
                  {goods.map((good, i) => (
                    <MediaBlock
                      isLarge={i === 1}
                      key={good.id}
                      mediaType={good.media_type}
                      tag={good.filter_type}
                      thumbnail={good.media_image.url}
                      externalLink={asLink(good.external_link)}
                      title={asText(good.title)}
                      onClick={() => openGoodModal(good)}
                    />
                  ))}
                </div>
              </div>
            )}
            <CTA selected />
          </div>
          <div className="expertise-modal__contact">
            <TextWithCTA
              data={{
                headline: "Get in Touch",
                body: "We're ready and waiting to make your goals a reality. Drop us a line with your specific needs, and the right person will be in touch.",
                cta: "Contact Us",
              }}
              handleClick={showContactForm}
            />
            <img src={medialinkBlackLogo} alt="UTA MediaLink" />
          </div>
        </div>
      )}
    </>
  )
}

export default ExpertiseModal
