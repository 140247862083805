/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"

import ContainedButton from "../ContainedButton"

import "./Form.scss"

function MLForm({
  groups,
  formConfig,
  setFormStatus,
  formId,
  submitText = "Submit",
}) {
  const mktoInterval = useRef(null)
  const formInterval = useRef(null)
  const form = useRef(null)
  const dummyForm = useRef(null)
  const [mktoForm, setMktoForm] = useState(null)
  const [formLoaded, setFormLoaded] = useState(false)
  const [focused, setFocused] = useState({})
  const [errors, setErrors] = useState({})
  const [fields, setFields] = useState(null)
  const [model, setModel] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [blurred, setBlurred] = useState({})
  const [validated, setValidated] = useState(true)
  const [maxHeight, setMaxHeight] = useState(null)
  const [ready, setReady] = useState(false)

  const loadMkto = () => {
    if (!formLoaded && form.current) {
      window.MktoForms2.whenRendered((formRes) => {
        if (form.current) {
          if (
            formRes.getFormElem() &&
            formRes.getFormElem()[0].getAttribute("data-form") &&
            formRes.getFormElem()[0].getAttribute("data-form") === formId
          ) {
            setMktoForm(formRes)
            formRes.onSubmit(() => {
              setMaxHeight(dummyForm.current && dummyForm.current.offsetHeight)
              setSubmitting(true)
              setFormStatus(true)
            })
            formRes.onSuccess(() => {
              setSubmitting(false)
              setSuccess(true)
              setFormStatus("success")
              return false
            })
          }
        }
      })
      document.getElementById(`mktoForm_${formConfig[2]}`).innerHTML = ""
      window.MktoForms2.loadForm(formConfig[0], formConfig[1], formConfig[2])
      setFormLoaded(true)
    }
  }

  const validateEmail = (_, value) => {
    let error
    if (!value) {
      error = "Field Mandatory"
    } else {
      let isEmail = value
        .toLowerCase()
        .match(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g)
      isEmail = value.trim().toLowerCase().match(/\s/g) ? false : isEmail
      if (isEmail) {
        error = null
      } else {
        error = "This isn't an email address"
      }
    }
    return error
  }

  const validateInput = (value, type, name) => {
    const updatedErrors = { ...errors }
    if (fields[name].required) {
      if (type === "email") {
        updatedErrors[name] = validateEmail(name, value)
      } else if (!value) {
        updatedErrors[name] = "Field Mandatory"
      } else {
        updatedErrors[name] = null
      }
    } else {
      updatedErrors[name] = false
    }
    setErrors({ ...updatedErrors })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (mktoForm.validate()) {
      setValidated(true)
      mktoForm.submit()
    } else {
      setValidated(false)
      const updatedErrors = { ...errors }
      Object.keys(fields).forEach((fieldKey) => {
        const field = fields[fieldKey]
        const { name } = field
        const { type } = field
        const value = model[fieldKey]
        if (fields[name].required) {
          switch (type) {
            case "email":
              updatedErrors[name] = validateEmail(name, value)
              break
            case "checkbox_group":
              updatedErrors[name] = value ? null : "Please select one"
              break
            default:
              updatedErrors[name] = value ? null : "Field Mandatory"
              break
          }
        } else {
          updatedErrors[name] = null
        }
      })
      setErrors({ ...updatedErrors })
    }
  }

  const parseForm = () => {
    const formInputs = {}
    const inputs = form.current.querySelectorAll("input, textarea, select")
    const initialValues = {}
    let optionsEls
    const optionsArray = []
    inputs.forEach((input) => {
      const type =
        input.tagName.toLowerCase() === "textarea"
          ? "textarea"
          : input.tagName.toLowerCase() === "select"
          ? "select"
          : input.getAttribute("type")
      const name = input.getAttribute("name")
      const required = input.getAttribute("aria-required")
      const value = input.getAttribute("value")
      initialValues[name] = ""
      let label
      let id
      const inputConfig = {
        type,
        name,
        required,
        el: input,
      }
      switch (type) {
        case "textarea":
        case "text":
        case "email":
          label =
            input &&
            input.offsetParent &&
            input.offsetParent
              .querySelector("label")
              .textContent.replace(/\*/g, "")
              .trim()
          label = label || input.getAttribute("placeholder")
          formInputs[name] = {
            ...inputConfig,
            value,
            label,
          }
          break
        case "checkbox":
        case "radio":
          if (input.offsetParent) {
            input.offsetParent
              .querySelectorAll("label")
              .forEach((labelNode) => {
                if (
                  labelNode.textContent
                    .replace(/\*/g, "")
                    .replace(/:/g, "")
                    .trim() !== ""
                ) {
                  label = labelNode.textContent
                    .replace(/\*/g, "")
                    .replace(/:/g, "")
                    .trim()
                  label = label.replace(
                    /Terms & Conditions/g,
                    "<a href='https://medialink.com/terms-of-service' target='_blank'>Terms & Conditions</a>"
                  )
                }
              })
          }
          id = input.getAttribute("id")
          if (formInputs[name]) {
            formInputs[name].type = "checkbox_group"
            formInputs[name].options.push({ value, id })
          } else {
            formInputs[name] = {
              ...inputConfig,
              label,
              options: [{ value, id }],
            }
          }
          break
        case "select":
          label =
            input &&
            input.offsetParent &&
            input.offsetParent
              .querySelector("label")
              .textContent.replace(/\*/g, "")
              .replace(/:/g, "")
          label = label || input.getAttribute("placeholder")
          id = input.getAttribute("id")
          optionsEls = input.querySelectorAll("option")
          formInputs[name] = { ...inputConfig }
          formInputs[name].type = "select"
          optionsEls.forEach((optionNode) => {
            optionsArray.push({
              id: optionNode.getAttribute("value"),
              label: optionNode.textContent,
            })
          })
          formInputs[name].options = optionsArray
          break
        default:
          break
      }
    })
    setModel(initialValues)
    setFields(formInputs)
    setReady(true)
  }

  const fieldChange = (e) => {
    const name = e.target.getAttribute("name")
    const { value } = e.target
    const type = e.target.getAttribute("type")
    const updatedModel = { ...model }
    updatedModel[name] = value
    form.current.querySelector(`#${name}`).value = value
    setModel({ ...updatedModel })
    if (blurred[name] || !validated) {
      validateInput(value, type, name)
    }
  }

  const fieldFocus = (e) => {
    const name = e.target.getAttribute("name")
    const updatedFocus = { ...focused }
    updatedFocus[name] = true
    setFocused({ ...updatedFocus })
  }

  const fieldBlur = (e) => {
    const name = e.target.getAttribute("name")
    const type = e.target.getAttribute("type")
    const { value } = e.target
    if (!value) {
      const updatedFocus = { ...focused }
      updatedFocus[name] = false
      setFocused({ ...updatedFocus })
    }
    const updatedBlurs = { ...blurred }
    updatedBlurs[name] = true
    setBlurred(updatedBlurs)
    validateInput(value, type, name)
  }

  const checkboxGroupChange = (e, value) => {
    const name = e.target.getAttribute("name")
    const targetId = e.target.getAttribute("data-target-id")
    const updatedModel = { ...model }
    const updatedErrors = { ...errors }
    updatedErrors[name] = false
    setErrors(updatedErrors)
    form.current.querySelector(`#${targetId}`).checked = e.target.checked
    if (updatedModel[name]) {
      const valIndex = updatedModel[name].indexOf(value)
      if (valIndex === -1) {
        updatedModel[name] = [...updatedModel[name], value]
      } else {
        updatedModel[name].splice(valIndex, 1)
      }
    } else {
      updatedModel[name] = [value]
    }
    setModel(updatedModel)
  }

  const checkboxChange = (e) => {
    const name = e.target.getAttribute("name")
    const targetId = e.target.getAttribute("data-target-id")
    const updatedModel = { ...model }
    form.current.querySelector(`#${targetId}`).checked = e.target.checked
    updatedModel[name] = updatedModel[name] ? null : e.target.value
    setModel(updatedModel)
  }

  const getField = (fieldKey) => {
    const field = fields[fieldKey]
    switch (field.type) {
      case "text":
      case "email":
        return (
          <div
            key={formId + fieldKey}
            className={classNames("ml-form__control ml-form__control--text", {
              "ml-form__control--focused": focused[field.name],
              "ml-form__control--error": errors[field.name],
            })}
          >
            <label htmlFor={formConfig[2] + field.name}>{field.label}</label>
            <input
              type={field.type}
              id={formConfig[2] + field.name}
              name={field.name}
              onChange={fieldChange}
              onFocus={fieldFocus}
              onBlur={fieldBlur}
            />
            <div className="ml-form__control-error">{errors[field.name]}</div>
          </div>
        )
      case "select":
        return (
          <div
            key={formId + fieldKey}
            className={classNames("ml-form__control ml-form__control--select", {
              "ml-form__control--focused": focused[field.name],
              "ml-form__control--error": errors[field.name],
            })}
          >
            <label htmlFor={formConfig[2] + field.name}>{field.label}</label>
            <select
              type={field.type}
              id={formConfig[2] + field.name}
              name={field.name}
              onChange={fieldChange}
              onFocus={fieldFocus}
              onBlur={fieldBlur}
            >
              {field.options.map((option) => (
                <option key={option.id + option.label} value={option.id}>
                  {option.label}
                </option>
              ))}
            </select>
            <div className="ml-form__control-error">{errors[field.name]}</div>
          </div>
        )
      case "checkbox_group":
        return (
          <div
            key={formId + fieldKey}
            className={classNames(
              "ml-form__control ml-form__control--checkbox-group",
              {
                "ml-form__control--focused": focused[field.name],
                "ml-form__control--error": errors[field.name],
              }
            )}
          >
            <legend>{field.label}</legend>
            {field.options.map((option) => (
              <div
                key={field.name + option.value}
                className={classNames(
                  "ml-form__control ml-form__control--checkbox",
                  {
                    "ml-form__control--checked":
                      model[field.name] &&
                      model[field.name].indexOf(option.value) >= 0,
                  }
                )}
              >
                <label htmlFor={`${field.name}-${option.value}`}>
                  {option.value}
                </label>
                <input
                  type="checkbox"
                  name={field.name}
                  value={option.value}
                  id={`${field.name}-${option.value}`}
                  data-target-id={option.id}
                  onChange={(e) => checkboxGroupChange(e, option.value)}
                />
              </div>
            ))}
            <div className="ml-form__control-error">{errors[field.name]}</div>
          </div>
        )
      case "checkbox":
        return (
          <div
            key={formId + fieldKey}
            className={classNames("ml-form__control ml-form__control--toggle", {
              "ml-form__control--focused": focused[field.name],
              "ml-form__control--checked": model[field.name],
              "ml-form__control--error": errors[field.name],
            })}
          >
            <label
              htmlFor={`ml-${formConfig[2]}${field.options[0].id}`}
              dangerouslySetInnerHTML={{ __html: field.label }}
            />
            <input
              type="checkbox"
              name={field.name}
              id={`ml-${formConfig[2]}${field.options[0].id}`}
              value={field.options[0].value}
              data-target-id={field.options[0].id}
              onChange={checkboxChange}
            />
            <div className="ml-form__control-error">{errors[field.name]}</div>
          </div>
        )
      case "textarea":
        return (
          <div
            key={formId + fieldKey}
            className={classNames(
              "ml-form__control ml-form__control--textarea",
              {
                "ml-form__control--focused": focused[field.name],
                "ml-form__control--error": errors[field.name],
              }
            )}
          >
            <label htmlFor={formConfig[2] + field.name}>{field.label}</label>
            <textarea
              name={field.name}
              id={formConfig[2] + field.name}
              onChange={fieldChange}
              onFocus={fieldFocus}
              onBlur={fieldBlur}
            />
            <div className="ml-form__control-error">{errors[field.name]}</div>
          </div>
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    mktoInterval.current = setInterval(() => {
      if (window.MktoForms2) {
        loadMkto()
        clearInterval(mktoInterval.current)
      }
    }, 1000)
    formInterval.current = setInterval(() => {
      if (
        form &&
        form.current &&
        form.current.children &&
        form.current.children.length
      ) {
        clearInterval(formInterval.current)
        parseForm()
      }
    })
  }, [])

  return (
    <div className="form-wrapper">
      <form
        onSubmit={handleSubmit}
        ref={dummyForm}
        style={{ maxHeight }}
        className={classNames("ml-form", {
          "ml-form--ready": ready,
          "ml-form--submitting": submitting,
          "ml-form--success": success,
        })}
        noValidate
      >
        {groups &&
          fields &&
          groups.map((group, index) => (
            <div
              className={`ml-form__group ml-form__group--${index}`}
              key={formId + group.keys.join("-")}
            >
              {group.label && (
                <div className="ml-form__group-label">{group.label}</div>
              )}

              {group.keys.map((fieldKey) => getField(fieldKey))}
            </div>
          ))}
        {groups && fields && (
          <div className="submit-wrapper">
            <ContainedButton text={submitText} type="submit" />
          </div>
        )}
      </form>
      <form
        id={`mktoForm_${formConfig[2]}`}
        className="mkto-form"
        ref={form}
        data-form={formId}
      />
    </div>
  )
}

export default MLForm
