import React, { useEffect, useState } from "react"
import { PrismicText } from "@prismicio/react"
import { asText } from "@prismicio/helpers"
import ContainedButton from "../../../common/ContainedButton"
import HoverTooltip from "../../../common/HoverTooltip"

import "./OpenRoles.scss"

function OpenRoles({ jobListings }) {
  const [noListings, setNoListings] = useState(false)
  useEffect(() => {
    if (jobListings && jobListings.length === 0) {
      setNoListings(true)
    } else {
      setNoListings(false)
    }
  }, [jobListings])
  return (
    <div className="open-roles">
      <div className="eyebrow">OPEN ROLES</div>
      <ul>
        {noListings && (
          <li>
            <span className="role-name">No current open roles</span>
          </li>
        )}
        {jobListings &&
          jobListings.length > 0 &&
          jobListings.map((role, index) => (
            <HoverTooltip
              text="Apply"
              key={`role-${index}-${
                asText(role.title) + asText(role.location)
              }`}
            >
              <li>
                <a
                  aria-label="Apply"
                  href={role.external_link.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="role-name">
                    <PrismicText field={role.title} />
                  </span>
                  <span className="role-location">
                    <PrismicText field={role.location} />
                  </span>
                </a>
              </li>
            </HoverTooltip>
          ))}
      </ul>
      <ContainedButton
        onClick={() => {
          window.open(
            "https://unitedtalent.wd5.myworkdayjobs.com/UTA",
            "_blank"
          )
        }}
        outlink
        text="View all open roles"
      />
    </div>
  )
}

export default OpenRoles
